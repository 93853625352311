import React from "react";
import "./button.css";


const Button = ({text, onClick, disabled}) => {
    return (
        <button className="btn default" 
            type="submit" 
            onClick={onClick} 
            disabled={disabled ? disabled : false}>
            <p>{text}</p>
        </button>
    )
}

export default Button;