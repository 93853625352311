import React, { useRef, useState } from 'react';
import './contact.css';
import mailImage from '../../assets/umailbox.svg';
import { Input, TextArea, Consent, Button } from '../../components'; 
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from '@emailjs/browser';

    
const MESSAGE_STATUS_OK = "Wiadomość została wysłana!";
const MESSAGE_STATUS_BAD = "Wiadomość nie została wysłana!";
const RECAPTCHA_KEY = "6LdLaQcgAAAAACV2rkyo2MN3iPYPI25-M4HuNQ20";

const EMAILJS_SERVICE_ID = "service_egslrfu";
const EMAILJS_TEMPLATE_ID = "template_ddhwzyp";
const EMAILJS_PUBLIC_KEY = "e7PhRgwUmjJE9UIRN";

const Contact = () => {

    const form = useRef();
    const initialValues = { 
        name: "", 
        surname: "", 
        phone: "", 
        email: "", 
        company: "", 
        subject: "", 
        message: ""
    }
    const phoneConsent = {
        id: "phoneConsent",
        text: "Wyrażam zgodę na kontakty telefoniczne."
    };
    const emailConsent = {
        id: "emailConsent",
        text: "Wyrażam zgodę na otrzymywanie e-maili."
    };
    const consents =[phoneConsent, emailConsent];

   
    const [ messageStatus, setMessageStatus ] = useState();
    const [ formValues, setFormValues ] = useState(initialValues);
    const [ buttonDisabled, setButtonDisabled ] = useState(true);
 
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues( { ...formValues, [name] : value} );
    }

    const handleRecapchaChange = (e) => {
        setButtonDisabled(e === null ? true : false);
    }
  
    const sendEmail = (e) => {
      e.preventDefault();
      const messageStatusEl = document.querySelector(".contact__message--status");
      messageStatusEl.classList.remove("bad");
      messageStatusEl.classList.remove("success");
      
      emailjs.sendForm(EMAILJS_SERVICE_ID, EMAILJS_TEMPLATE_ID, e.target, EMAILJS_PUBLIC_KEY)
      .then(() => {
            e.target.reset();
            setFormValues(initialValues);
            messageStatusEl.classList.add("success");
            setMessageStatus(MESSAGE_STATUS_OK);
            const inputs = document.querySelectorAll(".input__touched");
            for (let i = 0; i < inputs.length; i++) {
                inputs[i].classList.remove("input__touched");
            };
        }, (error) => {
            messageStatusEl.classList.add("bad");
            setMessageStatus(MESSAGE_STATUS_BAD);
        });
    };
    return (
        <div className="contact section__padding">
            <div className="section__header">
                <h1 className="gradient__text">Kontakt</h1>
            </div>
            <div className="contact__body">
                <form className="contact__contact--container" ref={form} onSubmit={sendEmail}>
                    <div className="contact__contact--col">
                        <div className="input__col2">
                            <div className="input__container">
                                <Input label="Imię" 
                                name="name" value={formValues.name} onChange={handleChange} required={true}></Input>     
                            </div>
                            <div className="input__container">
                                <Input label="Nazwisko" 
                                name="surname" value={formValues.surname} onChange={handleChange} required={true}></Input>     
                            </div>
                        </div>
                        <div className="input__col2">
                            <div className="input__container">
                                <Input label="Telefon" 
                                name="phone" value={formValues.phone} onChange={handleChange} required={true}></Input>     
                            </div>
                            <div className="input__container">
                                <Input label="Email" type="email"
                                 name="email" value={formValues.email} onChange={handleChange} required={true}></Input>     
                            </div>
                        </div>
                        <div className="input__container">
                            <Input label="Firma"
                             name="company" value={formValues.company} onChange={handleChange} required={true}></Input>     
                        </div>

                        
                    </div>
                    <div className="contact__contact--col">
                        <div className="input__container">
                            <Input label="Temat"
                             name="subject" value={formValues.subject} onChange={handleChange} required={true}></Input>     
                        </div>
                        <div className="input__container">
                            <TextArea label="Wiadomość"
                             name="message" value={formValues.message} onChange={handleChange} required={true}></TextArea>  
                        </div>
                        <ReCAPTCHA
                            sitekey={RECAPTCHA_KEY}
                            theme="dark"
                            className='contact__recaptcha'
                            onChange={handleRecapchaChange}
                            required
                        />

                        {consents.map((consent, i) => {
                                return (<Consent key={i} text={consent.text} id={consent.id} required={true}/>) 
                            }
                        )}
                        

                        <div className="contact__message--status input__container">
                            <p>{messageStatus}</p>
                        </div>
                        <div className={`input__container ${(buttonDisabled ? "btn-disabled": "")}`}>
                            <Button text="Wyślij" disabled={buttonDisabled}></Button>
                        </div>
                    </div>
                </form>
                <div className="contact__contact--image">
                    <img src={mailImage} alt="umail" />
                </div>
            </div>
        </div>
    )
}

export default Contact;