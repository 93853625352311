import React from 'react';
import './feature.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const Feature = ({title, text, icon}) => {
    return (
        <div className="feature__container">
            <div className="feature__container--effect"></div>
            {/* <div className="feature__title--bar"></div> */}
            <div className="feature__container--main">
                <div className="feature__icon">
                    <FontAwesomeIcon icon={icon} />
                </div>
                <div className="feature__title">      
                    <h1>{title}</h1>
                    <p>{text}</p>
                </div>
            </div>  
        </div>
    )
}

export default Feature;
