import React from 'react';
import './input.css';
const handleClick = (e) => {
    e.target.classList.add("input__touched");
}
const Input = ({label, required, type, name, value, onChange}) => {
    
    return (
        <label className="input">
            <input className="input__field" type={type ? type : 'text'} 
                placeholder=" "  
                name={ name ? name : "" }
                required={required ? required : false}
                value={value}
                onChange={onChange}
                onClick={handleClick}/>
            <span className="input__label">{label}{required ? "*" : ""}</span>
        </label>
    )
}
const TextArea = ({label, required, type, name, value, onChange}) => {
    return (
        <label className="input">
            <textarea className="input__field" type={type ? type : 'text'} 
                placeholder=" "  
                rows="5"
                name={name ? name : ""}
                required={required ? required : false}
                value={value}
                onChange={onChange ? onChange : null}
                onClick={handleClick}/>
            <span className="input__label">{label}{required ? "*" : ""}</span>
        </label>
    )
}

export { Input, TextArea };