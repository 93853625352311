import React, { useEffect, useState } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from '../../assets/ulogo-white.png';
import './navbar.css';
import Menu from '../menu/Menu';


const Navbar = () => {
    const [toggleMenu, setToggleMenu] = useState(false);
    const mobileMenuState = (open) => {
        setToggleMenu(open);
        const bodyEl = document.querySelector("body");
        if (open) {
            bodyEl.classList.add("mobile__menu--open");
        } else {
            bodyEl.classList.remove("mobile__menu--open");
        }
    }
    useEffect(() => {
        window.addEventListener("scroll", resizeHeaderOnScroll);
    },[]);
    let resizeHeaderOnScroll = () => {
        const distanceY = window.pageYOffset || document.documentElement.scrollTop;
        const shrinkOn = 150;
        const navbarEl = document.getElementById("navbar");

        if (distanceY > shrinkOn) {
            navbarEl.classList.add("navbar__shrink");
        } else {
            navbarEl.classList.remove("navbar__shrink");
        }
    };
    return (
        <div className="navbar gradient__bg" id="navbar">
            <div className="navbar__links">
                <div className = "navbar__links-logo">
                    <img src={logo} alt="logo"/>
                </div>
                <div className="navbar__links-container">
                    <Menu/>
                    <div className="navbar__effect"></div>
                </div>
            </div>
            <div className="navbar__mobile">
                {toggleMenu
                    ? <RiCloseLine size={27} onClick={() => mobileMenuState(false)} />
                    : <RiMenu3Line size={27} onClick={() => mobileMenuState(true)} />
                }
            </div>
            {toggleMenu && (
                <div className="navbar__mobile-container scale-up-center">
                    <Menu mobileMenuState={mobileMenuState}/>
                </div>
            )}
        </div>
    )
}

export default Navbar;
