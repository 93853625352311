import React from 'react';
import './footer.css';
import logo from '../../assets/ulogo-white.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faAt } from '@fortawesome/free-solid-svg-icons';



const Footer = () => {
    const year = new Date().getFullYear();
    const LINKEDIN_PAGE = "https://pl.linkedin.com/company/unbind";
    const FACEBOOK_PAGE = "https://www.facebook.com/Unbind-121132611854092";
    const infoMail = "info@unbind.pl";
    const MAIL_TO = `mailto:${infoMail}`;


    return (

        <div className="footer section__padding" id="contact">
            

            <div className="footer__links">
                <div className="footer__links_logo">
                    <img src={logo} alt="unbind_logo" />
                </div>
                
                <div className="footer__links_div">
                    <h4>UNBIND FILIP SZOT</h4>
                    <p>ul. Wojska Polskiego 1/1</p>
                    <p>78-400 Szczecinek</p>
                    <p>NIP: 6731899260</p>
                    <p>REGON: 361808969</p>
                </div>
                <div className="footer__links_div">
                    <h4>Kontakt</h4>
                    <p>+48 796-263-982</p>
                    <p>info@unbind.pl</p>
                    
                </div>
                <div className="footer__links_div">
                    <div className="social-container">
                        <ul className="social-icons">
                            <li><a href={LINKEDIN_PAGE} target="_blank" ><FontAwesomeIcon icon={faFacebookF} className="footer__social--icon"/></a></li>
                            <li><a href={FACEBOOK_PAGE} target="_blank"><FontAwesomeIcon icon={faLinkedinIn} className="footer__social--icon"/></a></li>
                            <li><a href={MAIL_TO}><FontAwesomeIcon icon={faAt} className="footer__social--icon"/></a></li>
                        </ul>
                    </div>    
                </div>
            </div>
            
            <div className="footer-copyright">
                <p>@{year} UNBIND All rights reserved.</p>
            </div>
        </div>
    );
}
export default Footer;