import React from 'react';
import { Brands, Navbar } from './components';
import { AboutUs, Header, Offer, Footer, Contact } from './containers';
import CookieConsent from "react-cookie-consent";

import './App.css';

const COOKIE_INFO = "Ta strona korzysta z plików cookies. Dalsze korzystnie ze strony oznacza, że zgadzasz się na ich użycie.";

const App = () => {
    return (
        <div className="App">
            <div className='gradient__bg'>
                <Navbar/>
                <div className="content__container">
                    <Header/>
                    <Brands/>
                    <AboutUs/>
                    <Offer/>
                    <Contact/>
                    <Footer/>
                    <CookieConsent 
                        buttonStyle={{ background: "#F8A826",  color: "#fff" }} buttonText="OK">
                        <p>{COOKIE_INFO}</p>
                    </CookieConsent>
                </div>
            </div>
        </div>
    )
}

export default App;
