import React from 'react';
import { acronis, bitdefender, eset, fortinet, hp, gdata, qnap, stormshield, synology, xopero, sophos } from './imports';
import './brands.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const brands = [ acronis, bitdefender, eset, fortinet, hp, gdata, qnap, stormshield, synology, xopero, sophos ];
const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    speed: 1500,
    arrows: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    cssEase: "linear",
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3
            }
        },
        {
            breakpoint: 650,
            settings: {
                slidesToShow: 1,
                fade: true,
            }
        }

    ]
  };

const Brands = () => {
    return (
        <div className="brand section__padding">
            <Slider {...settings}>
                {brands.map((brand, index ) => 
                    <div className='brand__image' key={index}>
                        <img src={brand} />
                    </div>
                )}
            </Slider>
        
            
        </div>
    )
}

export default Brands;
