import React, { useEffect } from 'react';
import './offer.css';
import Feature from '../../components/feature/Feature';
import { faCode, faDatabase, faFire, faNetworkWired, faServer, faShieldAlt, faShieldVirus, faUserShield } from '@fortawesome/free-solid-svg-icons';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';


let createSector = (title, text, icon) => {
    return {
        title: title,
        text: text,
        icon: icon
    }
}
const sectors = [
    createSector("OPROGRAMOWANIE ANTYWIRUSOWE", 
        "Bitdefender, G Data, ESET, FSecure, Trend Micro, Symantec, Sophos",
        faShieldAlt),
    createSector("WIELOFUNKCYJNE ZAPORY SIECIOWE",
        "Fortinet, Hillstone, Palo Alto, Stormshield, Sophos, Barracuda",
        faFire),
    createSector("USŁUGI",
        "Outsourcing, doradztwo, usługi programistyczne, wdroźenia",
        faCode),
    createSector("OPROGRAMOWANIE DO BACKUPU",
        "Acronis, Xopero, Storagecraft",
        faDatabase),
    createSector("ZARZADZANIE ZASOBAMI IT",
        "Safetica, Axence, Statlook",
        faNetworkWired),
    createSector("Data Loss Prevention oraz Szyfrowanie", 
        "Safetica, Forcepoint, Safend, ESET",  
        faUserShield),
    createSector("SERWERY I MACIERZE", 
        "HP, QNAP, Synology, DELL, Lenovo", 
        faServer)

]

const Offer = () => {
    useEffect(() => {
        gsap.set(".offer", { autoAlpha: 0 });
        gsap.registerPlugin(ScrollTrigger)
        const tl = gsap.timeline({ defaults: {ease: 'power3.inOut' }, scrollTrigger: {
            trigger: '.offer',
            start: 'top 25%',
        }});

        tl.fromTo(".offer", { autoAlpha: 0 }, {duration: 2, autoAlpha: 1 });
    });
    return (
        <div className="offer section__margin" id="offer">
            <div className="offer__features">
                {sectors.map((sector, i) => {     
                    return (<Feature key={i} title={sector.title} text={sector.text} icon={sector.icon} />) 
                })}
            </div>
        </div>
    )
}

export default Offer;
