import React from "react";


const Consent = ({id, text, required}) => {
    return (
        <div className="checkbox">
            <input className="checkbox__sign" id={id} type="checkbox" required={required ? required : false}/>
            <label className="checkbox__label" htmlFor={id}> {text} {required ? "*" : ""}</label>
        </div>
        
    )
}

export default Consent;