import React, { useRef, useEffect } from 'react';
import './aboutus.css';
import { ReactComponent as AboutUsImage } from "../../assets/rocket.svg";
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger)

const AboutUs = () => {
    
    const wrapperImage = useRef(null);
    const wrapperList = useRef(null);
    useEffect(() => {
        const [rocketElements] = wrapperImage.current.children;
        const aboutUsSection = document.querySelector('.about-us');
        const rocket = rocketElements.getElementById('rocket');
        const ring = rocketElements.getElementById('ring');
        const person = rocketElements.getElementById('person');
        const floor = rocketElements.getElementById('floor');
        const effects = rocketElements.getElementById('effects');
        const bubbles = rocketElements.getElementById('bubbles');
        gsap.set([rocket, ring, person, effects, bubbles, aboutUsSection], { autoAlpha: 0 });

        
        const aboutUsContent = document.querySelector('.about-us__content');

        const textTl = gsap.timeline({ defaults: {ease: 'power3.inOut' }, scrollTrigger: {
            trigger: '.about-us',
            start: 'top 25%',
        }});
        const tl = gsap.timeline({ defaults: {ease: 'power3.inOut' }, scrollTrigger: {
            trigger: '.about-us',
            start: 'top 25%',
        }});
        tl.to(aboutUsSection, {autoAlpha: 1});
        tl.fromTo(person, {x: '-=50', y:'-=50'}, {duration: .3, x: '+=50', y:'+=50', autoAlpha: 1});
        tl.fromTo(rocket, {x: '-=100', y:'-=400'}, {duration: .5, x: '+=100', y:'+=400', autoAlpha: 1});
        tl.fromTo(ring, {x: '-=10', y:'-=10'}, {duration: .5, x: '+=10', y:'+=10', autoAlpha: 1})
        .fromTo(ring, {duration: .1 ,y: '-10', yoyo: true, repeat: 3}, {duration: .3, y: '10', yoyo: true, repeat: 3});
        tl.to([bubbles, effects], {duration: .1 ,y: '-5', yoyo: true, repeat: 2, autoAlpha: 1});
        textTl.fromTo(aboutUsContent, { autoAlpha: 0 }, {duration: 2, autoAlpha: 1 });
    });
    return (
        <div className="about-us section__padding" id="about_us">

            <div className="section__header">
                <h1 className="gradient__text">O nas</h1>
            </div>

            <div className="about-us__body">
                <div className="about-us__image" ref={wrapperImage}>
                    <AboutUsImage/>
                </div>
                <div className="about-us__content">
                    <div className="about-us__text">
                        <h4>Naszą misją jest dostarczanie rozwiązań szytych na miarę.</h4>
                        <p>Od 2015 roku dostarczamy dla Państwa innowacyjne produkty w bardzo dobrym stosunku cenowym. 
                            Jesteśmy pasjonatami nowoczesnych technologii. Dlatego wszystkie oferowane rozwiązania poddajemy wnikliwej analizie i dopasowujemy je do Państwa potrzeb.
                            Współpraca z Państwem to dla nas coś więcej niż sama oferta lub transakcja. 
                            Dlatego świadczymy ciągłe wsparcie na każdym etapie projektu.
                        </p>
                        <h4>Co oferujemy?</h4>
                        
                    </div>
                    <ul className="about-us__list" ref={wrapperList}>
                        <li><FontAwesomeIcon icon={faCheckSquare}/> <span>Badanie potrzeb</span></li>
                        <li><FontAwesomeIcon icon={faCheckSquare}/> <span>Dobór optymalnych rozwiązań</span></li>
                        <li><FontAwesomeIcon icon={faCheckSquare}/> <span>Prezentacje produktowe</span></li>
                        <li><FontAwesomeIcon icon={faCheckSquare}/> <span>Pełne wsparcie przed i po sprzedażowe na etapie całego projektu</span></li>
                        <li><FontAwesomeIcon icon={faCheckSquare}/> <span>Wdrożenia, testy oraz integracja</span></li>
                    </ul>
                </div>

            </div>


            

        </div>
    )
}

export default AboutUs
