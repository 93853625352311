import React from 'react';
import { Link } from 'react-scroll';

const durationScroll = 100;
const offsetScroll = -200;
const Menu = ({mobileMenuState}) => (
    
    <>
        <p>
            <Link activeClass="active" to="home" spy={true} smooth={true} offset={offsetScroll} duration={durationScroll}
                onClick={mobileMenuState ? () => mobileMenuState(false) : null}>
                Home
            </Link>
        </p>
        <p>
            <Link activeClass="active" to="about_us" spy={true} smooth={true} offset={offsetScroll} duration={durationScroll}
                onClick={mobileMenuState ? () => mobileMenuState(false) : null}>
                O nas
            </Link>
        </p>
        <p>
            <Link activeClass="active" to="offer" spy={true} smooth={true} offset={offsetScroll} duration={durationScroll}
                onClick={mobileMenuState ? () => mobileMenuState(false) : null}>
                Oferta
            </Link>
        </p>
        
        <p>
            <Link activeClass="active" to="contact" spy={true} smooth={true} offset={offsetScroll} duration={durationScroll}
                onClick={mobileMenuState ? () => mobileMenuState(false) : null}>
                Kontakt
            </Link>
        </p>
    </>
)

export default Menu;