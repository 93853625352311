import React, { useEffect, useRef } from 'react';
import './header.css';
import uinternet from '../../assets/uinternet.svg';
import './header.css';
import { init } from 'ityped';
import Particles from "react-tsparticles";




const Header = () => {
    const typedText = useRef(null);
    const particleParams = {
        fpsLimit: 60,
        fullScreen: {
          enable: false
        },
          particles: {
            color: {
              value: "#F8A826"
            },
            links: {
              enable: true,
              color: "#F8A826",
              distance: 150
            },
            move: {
              enable: true,
              speed: 5,
              outModes: {
                default: "bounce"
              }
            }
        },
        interactivity: {
            events: {
              onHover: {
                mode: ['grab', 'bubble'],
                enable: true
              }
            },
            modes: {
              grab: {
                distance: 200
              },
              bubble: {
                  size: 10
              }
            }
        }
      };


    useEffect(() => {
        if (!typedText.current) {
            return;
        }
        init(typedText.current, { 
            strings: ['Nowoczesne technologie', 
                'Wysoka skuteczność',
                'Atrakcyjne warunki cenowe',
                'Wsparcie techniczne'
            ],
            showCursor: false,
            cursorChar: '|'
        })
      }, []);
    return (
        <div className="header section__padding" id="home">
            <div className="particles__container">
                <Particles className="particles" params={particleParams}/>
            </div>
            <div className="header__content">
                <h1>Bezpieczeństwo IT na kazdą kieszeń.</h1>
                <p className="typed-wrap"><span className="typed" ref={typedText}></span></p>
            </div>

            <div className="header__image">
                <img src={uinternet} />
            </div>
        </div>
    )
}

export default Header
